import { useEffect, useContext, useState } from "react";
import { getContracts } from "../../apis/getContracts";
import { GlobalContext } from "../../hooks/globalStateManager";
import { ContractRow } from "../../types/row/ContractRow";
import { FormProps } from "../../types/props/FormProps";
import { Form } from "./Form";
import { Results } from "./Results";
import { validation } from "../../lib/formValidation";

export const formInitValue: FormProps = {
  user_type: "person",
  user_name: "",
  telema_id_owner_card_id: "",
  member_sequence: "",
  family_name_kana: "",
  given_name_kana: "",
  corporation_kana: "",
  phone_number: "",
};

export function SearchLayout() {
  const { idToken } = useContext(GlobalContext);
  // TODO: この辺いい感じにまとめてcustom hookにする
  const [form, setForm] = useState(formInitValue);
  const [isMounted, setIsMounted] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState([] as ContractRow[]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = () => {
    setIsLoading(true);
    setPage(0);
    setRowsPerPage(25);

    // 表示ページが0のときは検索が実行されないので明示的に実行
    // TODO:ここ常に0では。。。？setPageの裏の更新タイミングが大丈夫か一応確認したい
    if (!page) {
      fetchContracts();
    }
  };

  const fetchContracts = async () => {
    // 性能改善の緊急対応、汚いのでformのvalidationはformでやりたい
    const v = validation(form);
    if (v.status) {
      const { data, meta } = await getContracts(
        form,
        page,
        rowsPerPage,
        idToken
      );
      setRows(data);
      setCount(meta.total_size);
      setIsLoading(false);
    } else {
      alert(v.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (idToken === "") {
      // ログアウトしたときにpageをリセットするので走ってしまう
      // 多分useEffect内であまり分岐するべきじゃない、フローを見直したほうがよさそう
    } else if (isMounted) {
      fetchContracts();
    } else {
      // 画面読み込み時は何もしない
      setIsMounted(true);
    }
  }, [rowsPerPage, page]);

  //cognitoのログイン画面が上にかぶせてるだけなのでunmountがつかめない
  useEffect(() => {
    setForm(formInitValue);
    setRows([]);
    setPage(0);
    setIsLoading(false);
  }, [idToken]);
  return (
    <div>
      <Form form={form} setForm={setForm} handleSearch={handleSearch} />
      <Results
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rows={rows}
        count={count}
        loading={isLoading}
      />
    </div>
  );
}
