import {
  Box,
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import { FormProps } from "../../types/props/FormProps";
import { formInitValue } from "./SearchLayout";

interface Props {
  form: FormProps;
  setForm: React.Dispatch<React.SetStateAction<FormProps>>;
  handleSearch: () => void;
}

const useStyles = makeStyles({
  telemaIdInput: {
    width: "250px",
  },
});

export function Form(props: Props) {
  const { form, setForm, handleSearch } = props;
  const classes = useStyles();
  return (
    <form>
      <Box
        display="flex"
        flexDirection="column"
        style={{ paddingLeft: "16px" }}
      >
        <Box display="flex">
          <Box p={1}>
            <FormControl component="fieldset">
              <FormLabel component="legend">ユーザー種別</FormLabel>
              <RadioGroup
                defaultValue="person"
                onChange={(e) => {
                  const userType =
                    e.target.value === "person" ? "person" : "corporation";
                  setForm({ ...form, user_type: userType });
                }}
                row
              >
                <FormControlLabel
                  value="person"
                  checked={form.user_type === "person"}
                  control={<Radio />}
                  label="個人"
                />
                <FormControlLabel
                  value="corporation"
                  checked={form.user_type === "corporation"}
                  control={<Radio />}
                  label="法人"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box p={1}>
            <TextField
              label="ID(メールアドレス)"
              id="user_name"
              value={form.user_name}
              onChange={(e) => setForm({ ...form, user_name: e.target.value })}
            />
          </Box>
          <Box p={1}>
            <TextField
              label="テレマID/オーナーズカードID"
              value={form.telema_id_owner_card_id}
              onChange={(e) =>
                setForm({ ...form, telema_id_owner_card_id: e.target.value })
              }
              className={classes.telemaIdInput}
            />
          </Box>
          <Box p={1}>
            <TextField
              label="会員シーケンス"
              value={form.member_sequence}
              onChange={(e) =>
                setForm({ ...form, member_sequence: e.target.value })
              }
            />
          </Box>
        </Box>

        <Box display="flex">
          <Box p={1}>
            <TextField
              label="姓カナ"
              value={form.family_name_kana}
              onChange={(e) =>
                setForm({ ...form, family_name_kana: e.target.value })
              }
            />
          </Box>
          <Box p={1}>
            <TextField
              label="名カナ"
              value={form.given_name_kana}
              onChange={(e) =>
                setForm({ ...form, given_name_kana: e.target.value })
              }
            />
          </Box>
          <Box p={1}>
            <TextField
              label="法人名カナ"
              id="corporation_kana"
              value={form.corporation_kana}
              onChange={(e) =>
                setForm({ ...form, corporation_kana: e.target.value })
              }
            />
          </Box>
          <Box p={1}>
            <TextField
              label="電話番号"
              id="phone_number"
              value={form.phone_number}
              onChange={(e) =>
                setForm({ ...form, phone_number: e.target.value })
              }
            />
          </Box>
          <Box
            p={1}
            style={{ lineHeight: 1, color: "red", fontWeight: "bold" }}
          >
            <p>※電話番号を用いた検索は時間がかかります。</p>
          </Box>
        </Box>
        <Box display="flex">
          <Box p={1}>
            <Button variant="outlined" onClick={handleSearch}>
              検索
            </Button>
          </Box>
          <Box p={1}>
            <Button variant="outlined" onClick={() => setForm(formInitValue)}>
              リセット
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
