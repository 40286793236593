import { TableRow, TableCell, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  label_ebeced: {
    backgroundColor: "#ebeced",
  },
});

export function InformationRow(props: any) {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell align="center" className={classes.label_ebeced}>
        {props.labelName}
      </TableCell>
      <TableCell align="left">{props.libra}</TableCell>
      <TableCell align="left">{props.telema}</TableCell>
    </TableRow>
  );
}
