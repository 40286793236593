import { errorDialog } from "../lib/errorDialog";
import { Contract } from "../types/Contract";

interface GetContractResult {
  data: Contract;
  meta: any;
}

export const getContract = async (
  scim_id: string,
  member_key: string,
  application_id: string,
  contract_key: string,
  idToken: string
): Promise<GetContractResult> => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const result = await fetch(`${API_ENDPOINT}/v1/contract`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({ scim_id, member_key, application_id, contract_key }),
  });

  let data: Contract = {
    contact_data: null,
    contract_cars: null,
    cooperation_shop_informations: [],
    libra_corporate_users: null,
  };
  // 現状使っていないがエラー回避のため
  let meta: any;
  if (result.ok) {
    const res = await result.json();
    data = res.data;
  } else {
    console.error(await result.text());
    errorDialog(result.status);
  }

  return { data, meta };
};
