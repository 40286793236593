import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import { InformationBody } from "./informationBody";
import { displayUserType } from "../../lib/userType";
import { parseDate } from "../../lib/parseDate";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  label_width: {
    width: "120px",
  },
  label_ebeced: {
    backgroundColor: "#ebeced",
  },
});

const toAddress = (add1: string, add2: string, add3: string): string =>
  (add1 ? add1 : "") + (add2 ? add2 : "") + (add3 ? add3 : "");

const toPostalCode = (postalCode1: string, postalCode2: string): string => {
  if (postalCode1 || postalCode2) {
    return (
      (postalCode1 ? postalCode1 : "") +
      " - " +
      (postalCode2 ? postalCode2 : "")
    );
  } else {
    return "";
  }
};
// 詳細画面のテーブルに設定するlibraの値を整形する（日付の整形、名前の整形、nullのとき空にするなど）
const toLibraView = (row: any) => {
  const userType = displayUserType(row.user_type);

  const hasName = row.family_name && row.given_name;
  const hasKanaName = row.family_name_kana && row.given_name_kana;
  const hasContactPersonName =
    row.contact_person_family_name && row.contact_person_given_name;
  const hasContactPersonKana =
    row.contact_person_family_name_kana && row.contact_person_given_name_kana;

  return {
    ...row,
    user_type: userType,
    formatted: hasName ? `${row.family_name} ${row.given_name}` : "",
    formatted_kana: hasKanaName
      ? `${row.family_name_kana} ${row.given_name_kana}`
      : "",
    birth_date: row.birth_date ? parseDate(row.birth_date, "yyyy/MM/dd") : "",
    contact_person_name: hasContactPersonName
      ? `${row.contact_person_family_name} ${row.contact_person_given_name}`
      : "",
    contact_person_kana: hasContactPersonKana
      ? `${row.contact_person_family_name_kana} ${row.contact_person_given_name_kana}`
      : "",
    scim_id: row.scim_id != null ? `${row.scim_id}` : "",
    last_modified: row.last_modified
      ? parseDate(row.last_modified, "yyyy/MM/dd HH:mm")
      : "",
    postal_code: toPostalCode(row.postal_code1, row.postal_code2),
  };
};

// 詳細画面のテーブルに設定するテレマの値を整形する（日付の整形、名前の整形、nullのとき空にするなど）
const toTeremaView = (row: any, locId: string, applicationId: string) => {
  const userType = displayUserType(row.member_category);

  const hasName = row.customer_last_name && row.customer_first_name;
  const hasNameKana = row.customer_last_kana && row.customer_first_kana;
  const hasCorporationPersonName =
    row.corporation_person_last_name && row.corporation_person_first_name;
  const hasCorporationPersonKana =
    row.corporation_person_last_kana && row.corporation_person_first_kana;

  return {
    ...row,
    user_type: userType,
    formatted: hasName
      ? `${row.customer_last_name} ${row.customer_first_name}`
      : "",
    formatted_kana: hasNameKana
      ? `${row.customer_last_kana} ${row.customer_first_kana}`
      : "",
    address: toAddress(row.address1, row.address2, row.address3),
    birthday: row.birthday ? parseDate(row.birthday, "yyyy/MM/dd") : "",
    locId: locId,
    applicationId: applicationId,
    internal_member_id:
      row.internal_member_id != null ? `${row.internal_member_id}` : "",
    corporation_person_name: hasCorporationPersonName
      ? `${row.corporation_person_last_name} ${row.corporation_person_first_name}`
      : "",
    corporation_person_kana: hasCorporationPersonKana
      ? `${row.corporation_person_last_kana} ${row.corporation_person_first_kana}`
      : "",
    update_time: row.update_time
      ? parseDate(row.update_time, "yyyy/MM/dd HH:mm")
      : "",
  };
};

// 詳細画面の共通IDとテレマのテーブル（個人・法人情報のテーブル）を作成する
export function Information(props: any) {
  const classes = useStyles();
  const { libraRow, telemaRow, carRow } = props;

  return (
    <div>
      <h2 style={{ paddingLeft: "16px" }}>個人・法人情報</h2>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.label_ebeced} ${classes.label_width}`}
              ></TableCell>
              <TableCell
                className={classes.label_ebeced}
                width="408px"
                align="center"
              >
                共通ID
              </TableCell>
              <TableCell
                className={classes.label_ebeced}
                width="408px"
                align="center"
              >
                テレマ
              </TableCell>
            </TableRow>
          </TableHead>
          <InformationBody
            classes={classes}
            libra={toLibraView(libraRow)}
            telema={toTeremaView(
              telemaRow,
              carRow ? carRow.owner_card_id : "",
              carRow ? carRow.application_id : ""
            )}
          />
        </Table>
      </TableContainer>
    </div>
  );
}
