import React, { createContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

export const GlobalContext = createContext();

// todo:hooksではないので移動したい
export function GlobalStateManager({ children }) {
  const [idToken, setIdToken] = useState("");

  useEffect(() => {
    onAuthUIStateChange(() => {
      Auth.currentSession().then((data) => {
        setIdToken(data.getIdToken().getJwtToken());
      });
    });
  }, [idToken]);
  return (
    <GlobalContext.Provider value={{ idToken, setIdToken }}>
      {children}
    </GlobalContext.Provider>
  );
}
