import "./App.css";
import { Header } from "./components/header";
import { GlobalStateManager } from "./hooks/globalStateManager";
import Amplify from "aws-amplify";
import awsmobile from "./aws-exports";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { SearchLayout } from "./components/search/SearchLayout";

Amplify.configure(awsmobile);

function App() {
  return (
    <AmplifyAuthenticator>
      {/* https://docs.amplify.aws/ui/auth/authenticator/q/framework/react/#props-slots-amplify-authenticator */}
      <AmplifySignIn slot="sign-in" hideSignUp />
      <GlobalStateManager>
        <Header />
        <SearchLayout />
      </GlobalStateManager>
    </AmplifyAuthenticator>
  );
}

export default App;
