export function errorDialog(status: number) {
  switch (status) {
    case 408:
      alert("データの取得に失敗しました。");
      break;
    case 401:
      alert(
        "認証情報の有効期限切れです。画面を更新して検索しなおしてください。"
      );
      break;
    default:
      alert("エラーが発生しました。");
  }
}
