export function displayUserType(userType: string): string {
  switch (userType) {
    case "個人":
    case "person":
      return "個人";
    case "一般法人":
    case "代理店法人":
    case "サプライヤー（法人）":
    case "corporation":
      return "法人";
    default:
      return "";
  }
}
