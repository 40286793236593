export function OtherService(props: any) {
  const serviceAuthNames = props.serviceAuthNames
    ? props.serviceAuthNames.map((serviceAuthName: string, index: number) => {
        return <li key={`${serviceAuthName}-${index}`}>{serviceAuthName}</li>;
      })
    : [];

  return (
    <div style={{ paddingLeft: "16px" }}>
      <h2>現在連携しているサービス</h2>
      <ul>{serviceAuthNames}</ul>

      <h2>付与されている権限</h2>
      <ul>
        <li>10月以降対応予定</li>
      </ul>
    </div>
  );
}
