import { Grid } from "@material-ui/core";
import { parseDate } from "../../lib/parseDate";

// 詳細画面の上部に表示する共通ID、会員シーケンス、最終更新日を設定する
export function UserInfo(props: any) {
  const { userRow, libraRow, telemaRow } = props;
  const lastModified =
    libraRow.last_modified > telemaRow.update_time
      ? libraRow.last_modified
      : telemaRow.update_time;
  return (
    <Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        style={{ paddingRight: "8px", textAlign: "right" }}
      >
        <Grid item style={{ padding: "8px" }}>
          <h4 style={{ lineHeight: "1" }}>ID</h4>
          <p>{userRow.id ? userRow.id : "共通IDに紐づいていません。"}</p>
        </Grid>
        <Grid item style={{ padding: "8px" }}>
          <h4 style={{ lineHeight: "1" }}>会員シーケンス</h4>
          <p>{userRow.seq ? userRow.seq : "共通IDに紐づいていません。"}</p>
        </Grid>
        <Grid item style={{ padding: "8px" }}>
          <h4 style={{ lineHeight: "1" }}>最終更新日</h4>
          <p>
            {lastModified ? parseDate(lastModified, "yyyy/MM/dd HH:mm") : ""}
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}
