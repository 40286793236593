import { ContractRow } from "../types/row/ContractRow";
import { FormProps } from "../types/props/FormProps";
import { errorDialog } from "../lib/errorDialog";

// metaが適当なので、必要に応じてちゃんと作る
interface GetContractsResult {
  data: ContractRow[];
  meta: { total_size: number };
}

export const getContracts = async (
  form: Partial<FormProps>,
  page: number,
  rowsPerPage: number,
  idToken: string
): Promise<GetContractsResult> => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const result = await fetch(`${API_ENDPOINT}/v1/contracts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      options: {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      },
      filter: form,
    }),
  });

  let data: ContractRow[] | [];
  let meta: any;

  if (result.ok) {
    const res = await result.json();
    data = res.data;
    meta = res.meta;
  } else {
    console.error(await result.text());
    data = [];
    meta = { total_size: 0 };
    errorDialog(result.status);
  }

  return { data, meta };
};
