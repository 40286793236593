import { useContext } from "react";
import { Grid, Button, Divider } from "@material-ui/core";
import { GlobalContext } from "../hooks/globalStateManager";
import { Auth } from "aws-amplify";

export function Header() {
  const { setIdToken } = useContext(GlobalContext);

  const handleLogout = () => {
    Auth.signOut()
      .then(() => {
        setIdToken("");
      })
      .catch();
  };

  return (
    <header>
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: "8px" }}
        >
          <Grid item style={{ fontSize: "22px" }}>
            CDP事務局viewer
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => handleLogout()}>
              logout
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
    </header>
  );
}
