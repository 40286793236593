import { useState } from "react";
import { makeStyles, Modal, Tooltip, Typography } from "@material-ui/core";
import { ContractLayout } from "../contract/ContractLayout";
import { DataGrid, GridColumns, GridRowData } from "@mui/x-data-grid";
import { ContractRow } from "../../types/row/ContractRow";
import { ContractKeyProps } from "../../types/props/ContractKeyProps";
import { displayUserType } from "../../lib/userType";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LibraLogo from "../../img/libra.drawio.svg";

const useStyles = makeStyles({
  table: {
    minWidth: "1366px",
  },
  infoIcon: {
    marginBottom: "-4px",
  },
  libraIcon: {
    marginBottom: "-2.5px",
    marginLeft: "1px",
  },
});

interface Props {
  page: number;
  rowsPerPage: number;
  rows: ContractRow[];
  count: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
}

export const contractKeyInitValue: ContractKeyProps = {
  scim_id: "",
  member_key: "",
  application_id: "",
  contract_key: "",
};

// 一覧画面・詳細画面の検索結果表示処理
export function Results(props: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { page, rowsPerPage, rows, count, setPage, setRowsPerPage, loading } =
    props;

  const [contractKey, setContractKey] = useState(contractKeyInitValue);

  const columns: GridColumns = [
    {
      field: "user_type",
      headerName: "ユーザー種別",
      sortable: false,
      width: 120,
      renderCell: ({ row }) => <span>{displayUserType(row.user_type)}</span>,
    },
    {
      field: "user_name",
      headerName: "ID(メールアドレス)",
      sortable: false,
      flex: 0.8,
    },
    {
      field: "member_sequence",
      headerName: "会員シーケンス",
      sortable: false,
      flex: 0.4,
    },
    {
      field: "contract_status",
      headerName: "契約ステータス",
      sortable: false,
      width: 140,
    },
    {
      field: "telema_id_owner_card_id",
      headerName: "テレマID/ オーナーズカードID",
      sortable: false,
      flex: 0.8,
    },
    {
      field: "family_name_kana",
      headerName: "姓カナ",
      sortable: false,
      flex: 0.6,
    },
    {
      field: "given_name_kana",
      headerName: "名カナ",
      sortable: false,
      flex: 0.6,
    },
    {
      field: "corporation_name_kana",
      headerName: "法人名カナ",
      sortable: false,
      flex: 0.6,
    },
    {
      field: "phone_number",
      headerName: "電話番号",
      sortable: false,
      flex: 0.5,
      renderHeader: () => (
        <Tooltip
          title="共通IDの電話番号にはLマークがつきます。"
          placement="top"
        >
          <span>
            <Typography component="span">電話番号</Typography>
            <InfoOutlinedIcon
              color="secondary"
              fontSize="small"
              className={classes.infoIcon}
            />
          </span>
        </Tooltip>
      ),
      renderCell: ({ row }) => (
        <div>
          {row.phone_number}
          {
            <Typography component="span" color="primary">
              {row.phone_number && row.phone_number_libra_flag === "1" && (
                <img
                  src={LibraLogo}
                  height={15}
                  width={15}
                  className={classes.libraIcon}
                  alt="libra"
                />
              )}
            </Typography>
          }
        </div>
      ),
    },
  ];

  const handleOpen = async ({ row }: GridRowData) => {
    setContractKey({
      scim_id: row.scim_id,
      member_key: row.member_key,
      application_id: row.application_id,
      contract_key: row.contract_key,
    });
    setOpen(true);
  };

  return (
    <div>
      <DataGrid
        columns={columns}
        rows={rows}
        page={page}
        pageSize={rowsPerPage}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => {
          setPage(0);
          setRowsPerPage(newPageSize);
        }}
        onRowClick={handleOpen}
        getRowId={(row: GridRowData) =>
          `${row.scim_id}-${row.user_name}-${row.application_id}-${row.member_key}-${row.contract_key}`
        }
        rowCount={count}
        className={classes.table}
        showCellRightBorder
        showColumnRightBorder
        autoHeight
        loading={loading}
        rowHeight={30}
        headerHeight={30}
        pagination
        paginationMode="server"
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ContractLayout contractKey={contractKey} />
      </Modal>
    </div>
  );
}
