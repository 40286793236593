const USER_POOLS_ID = process.env.REACT_APP_COGNITO_USER_POOLS_ID;
const WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_WEB_CLIENT_ID;

const awsmobile = {
  aws_project_region: "ap-northeast-1",
  aws_cognito_identity_pool_id: "ap-northeast-1:",
  aws_cognito_region: "ap-northeast-1",
  aws_user_pools_id: USER_POOLS_ID,
  aws_user_pools_web_client_id: WEB_CLIENT_ID,
  oauth: {},
  aws_cognito_login_mechanism: ["PREFERRED_USERNAME"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["OTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
};

export default awsmobile;
