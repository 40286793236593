export function displayGender(gender: string): string {
  switch (gender) {
    case "m":
      return "男";
    case "f":
      return "女";
    case "corporation":
      return "法人";
    default:
      return "";
  }
}
