import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import { dcmModelYear } from "../../lib/dcmModelYear";
import { parseDate } from "../../lib/parseDate";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  flame: {
    width: "110px",
    backgroundColor: "#ebeced",
  },
});

export function CarInfo(props: any) {
  const classes = useStyles();
  const { carRow } = props;

  return (
    <div>
      <h2 style={{ paddingLeft: "16px" }}>車両情報</h2>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell className={classes.flame} rowSpan={6} align="center">
                連係情報
              </TableCell>
              <TableCell className={classes.flame} align="center">
                メーカー
              </TableCell>
              <TableCell align="left">{carRow.vehicle_maker_code}</TableCell>
              <TableCell className={classes.flame} align="center">
                ステータス
              </TableCell>
              <TableCell align="left">{carRow.contract_status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.flame} align="center">
                車種
              </TableCell>
              <TableCell align="left">{carRow.car_name}</TableCell>
              <TableCell className={classes.flame} align="center">
                契約開始日
              </TableCell>
              <TableCell align="left">
                {carRow.current_contract_start_date_time
                  ? parseDate(
                      carRow.current_contract_start_date_time,
                      "yyyy/MM/dd"
                    )
                  : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.flame} align="center">
                VIN
              </TableCell>
              <TableCell align="left">{carRow.vin}</TableCell>
              <TableCell className={classes.flame} align="center">
                契約終了日
              </TableCell>
              <TableCell align="left">
                {carRow.current_contract_end_date_time
                  ? parseDate(
                      carRow.current_contract_end_date_time,
                      "yyyy/MM/dd"
                    )
                  : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.flame} align="center">
                DCM年式
              </TableCell>
              <TableCell align="left">
                {dcmModelYear(carRow.dcm_model_year, carRow.vin)}
              </TableCell>
              <TableCell className={classes.flame} align="center">
                解約日
              </TableCell>
              <TableCell align="left">
                {carRow.release_date_time
                  ? parseDate(carRow.release_date_time, "yyyy/MM/dd")
                  : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.flame} align="center">
                契約ID
              </TableCell>
              <TableCell align="left">{carRow.contract_id}</TableCell>
              <TableCell className={classes.flame} align="center">
                サービス名
              </TableCell>
              <TableCell align="left">{carRow.service_plan_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.flame} align="center">
                申し込みルート
              </TableCell>
              <TableCell align="left">
                {carRow.application_entry_root}
              </TableCell>
              <TableCell className={classes.flame} align="center">
                申し込み時販売店
              </TableCell>
              <TableCell align="left">
                {carRow.application_dealer_name}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
