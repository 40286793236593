import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import { parseDate } from "../../lib/parseDate";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  flame: {
    width: "120px",
    backgroundColor: "#ebeced",
  },
});

export function DealerInfo(props: any) {
  const classes = useStyles();
  //いらないかも
  const { dealerRows } = props;
  return (
    <div>
      <h2 style={{ paddingLeft: "16px" }}>販売店連係情報</h2>
      {dealerRows.map((row: any, index: number) => {
        return (
          <TableContainer key={`${row.pal_link_dealer_code}-${index}`}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell
                    className={classes.flame}
                    rowSpan={3}
                    align="center"
                  >
                    {`連係情報${index + 1}`}
                  </TableCell>
                  <TableCell className={classes.flame} align="center">
                    ディーラーコード
                  </TableCell>
                  <TableCell align="left">{row.pal_link_dealer_code}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.flame} align="center">
                    販売店名
                  </TableCell>
                  <TableCell align="left">{row.pal_dealer_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.flame} align="center">
                    連係日時
                  </TableCell>
                  <TableCell align="left">
                    {row.registration_time
                      ? parseDate(row.registration_time, "yyyy/MM/dd")
                      : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      })}
    </div>
  );
}
