import { FormValid } from "../types/lib/FormValid";
import { FormProps } from "../types/props/FormProps";

// Formリファクタリングまでの書き捨て
export function validation(f: FormProps): FormValid {
  // paramCheckでユーザが検索条件を入力しているかをチェックする
  const paramCheck =
    f.user_name !== "" ||
    f.telema_id_owner_card_id !== "" ||
    f.member_sequence !== "" ||
    f.family_name_kana !== "" ||
    f.given_name_kana !== "" ||
    f.corporation_kana !== "" ||
    f.phone_number !== "";
  const personCheck = f.user_type === "person" && f.corporation_kana === "";
  const corporationCheck =
    f.user_type === "corporation" &&
    f.family_name_kana === "" &&
    f.given_name_kana === "";
  const personPhoneNumber =
    f.family_name_kana !== "" || f.given_name_kana !== "";
  const corporationPhoneNumber = f.corporation_kana !== "";

  let message: string = "";
  if (paramCheck === false) {
    message += "検索条件を入力してください。";
  } else if (f.user_type === "person" && personCheck === false) {
    message += "ユーザー種別が個人の場合、法人名カナは検索できません。";
  } else if (f.user_type === "corporation" && corporationCheck === false) {
    message += "ユーザー種別が法人の場合、姓カナ、名カナは検索できません。";
  } else if (
    f.user_type === "person" &&
    f.phone_number !== "" &&
    personPhoneNumber === false
  ) {
    message +=
      "電話番号で検索する場合は、姓（カナ）・名（カナ）も入力してください。";
  } else if (
    f.user_type === "corporation" &&
    f.phone_number !== "" &&
    corporationPhoneNumber === false
  ) {
    message += "電話番号で検索する場合は、法人名カナも入力してください。";
  }
  return {
    status: message === "",
    message: message,
  };
}
