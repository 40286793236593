import { Grid, Paper, makeStyles, Divider } from "@material-ui/core";
import { UserInfo } from "./userInfo";
import { Information } from "./information";
import { CarInfo } from "./carInfo";
import { DealerInfo } from "./dealerInfo";
import { OtherService } from "./otherService";
import { ContractKeyProps } from "../../types/props/ContractKeyProps";
import { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../hooks/globalStateManager";
import { getContract } from "../../apis/getContract";
import { InformationRow } from "../../types/row/InformationRow";

const useStyles = makeStyles({
  modalSize: {
    height: "85vh",
    width: "100%",
    overflow: "auto",
  },
  modalPosition: {
    position: "absolute",
    //画面解像度を1440としたときの65%、レイアウト的にこれ以上狭くするのは厳しい気がする
    width: "936px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

interface Props {
  contractKey: ContractKeyProps;
}

//libra情報、テレマ情報等がないユーザはこのinitStateの値が設定される
//pertialにするのが多分一番影響が少ないので一旦。本当はこれをやめたい
const initState: InformationRow = {
  user: {
    id: "",
    seq: "",
  },
  libraRow: {
    user_type: "",
    formatted: "",
    family_name_kana: "",
    given_name_kana: "",
    birth_date: "",
    gender: "",
    address: "",
    cell_phone_number: "",
    mail_address: "",
    client_ids: "",
    scim_id: "",
    last_modified: "",
  },
  telemaRow: {
    import_time: "",
    customer_last_name: "",
    customer_first_name: "",
    customer_last_kana: "",
    customer_first_kana: "",
    birthday: "",
    sex_division: "",
    address1: "",
    address2: "",
    address3: "",
    member_phone_number: "",
    phone_number1: "",
    phone_number2: "",
    phone_number3: "",
    email_address1: "",
    email_address2: "",
    email_address3: "",
    update_time: "",
  },
  carRow: {
    vehicle_maker_code: "",
    car_name: "",
    vin: "",
    dcm_model_year: "",
    contract_id: 0,
    application_entry_root: "",
    contract_status: "",
    contract_date_time: "",
    current_contract_start_date_time: "",
    current_contract_end_date_time: "",
    release_date_time: "",
    service_plan_name: "",
    application_dealer_name: "",
    owner_card_id: "",
  },
  dealerRows: [
    {
      pal_link_dealer_code: "",
      pal_dealer_name: "",
      registration_time: "",
    },
  ],
};

const Flame = ({ children }: any) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.modalPosition}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid className={classes.modalSize} component={Paper}>
        {children}
      </Grid>
    </Grid>
  );
};

// 詳細画面の表示処理
// propsに入っているscim_id, member_key, application_id, contract_keyに応じた情報を表示する
// 一覧画面でクリックしたものによってpropsが変わる
export function ContractLayout(props: Props) {
  const { contractKey } = props;
  const { idToken } = useContext(GlobalContext);
  const [state, setState] = useState(initState);

  const fetch = async () => {
    // 契約情報の詳細を取得するAPIを呼び出す
    const { data } = await getContract(
      contractKey.scim_id,
      contractKey.member_key,
      contractKey.application_id,
      contractKey.contract_key,
      idToken
    );
    setState({
      user: {
        id: data.libra_corporate_users?.user_name || "",
        seq: data.libra_corporate_users?.member_sequence || "",
      },
      libraRow:
        data.libra_corporate_users != null
          ? data.libra_corporate_users
          : initState.libraRow,
      telemaRow:
        data.contact_data != null ? data.contact_data : initState.telemaRow,
      carRow:
        data.contract_cars != null ? data.contract_cars : initState.carRow,
      dealerRows:
        data.cooperation_shop_informations.length > 0
          ? data.cooperation_shop_informations
          : initState.dealerRows,
    });
  };

  useEffect(() => {
    fetch();

    return function crean() {
      setState({
        user: initState.user,
        libraRow: initState.libraRow,
        telemaRow: initState.telemaRow,
        carRow: initState.carRow,
        dealerRows: initState.dealerRows,
      });
    };
  }, []);
  // テーブルにAPIの結果を入れてreturnする
  return (
    <Flame>
      <UserInfo
        userRow={state.user}
        libraRow={state.libraRow}
        telemaRow={state.telemaRow}
      />
      <Divider />
      <Information
        libraRow={state.libraRow}
        telemaRow={state.telemaRow}
        carRow={state.carRow}
      />
      <CarInfo carRow={state.carRow} />
      <DealerInfo dealerRows={state.dealerRows} />
      <OtherService serviceAuthNames={state.libraRow.service_auth_names} />
    </Flame>
  );
}
