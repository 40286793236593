export function dcmModelYear(dcm: string, vin: string): string {
  if (vin === null || !vin) {
    return "";
  }
  switch (dcm) {
    case "19":
      return "19（19世代：19CY、20TM）";
    case "20":
      return "20（21世代：21MM）";
    case "":
      return "〜17（DCM年式未設定）";
    default:
      return "";
  }
}
